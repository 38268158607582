import { serverServices } from "../_services";
import { serverConstants } from "../_constants";

export const serverActions = {
    getAll, get
};

function getAll() {
    return dispatch => {
        serverServices.listServers().then(servers => {
            dispatch({type: serverConstants.GET_ALL_RESPONSE, servers: servers });
        }, err => console.log(err));
    }
}

function get(id) {
    return dispatch => {
        serverServices.getServer(id).then(server => {
            dispatch({type: serverConstants.GET_RESPONSE, server: server, id: id});
        })
    }
}