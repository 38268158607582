import axios from 'axios';
import { store } from "../_helpers";
import { authenticationService } from "./authentication.services";

const axiosInstance = axios.create({
    baseURL: process.env.CONFIG_API_BASE
});

axiosInstance.interceptors.request.use(axiosIntercept);
axiosInstance.interceptors.response.use(axiosResponseIntercept, axiosErrorIntercept);

function axiosIntercept(value) {
    const {authentication: authState } = store.getState();

    // If we have a valid logged-in state and a token, put the token in the request.
    if (authState && authState.token) {
        value.headers.authorization = 'Bearer ' + authState.token;
    }

    return value;
}

function axiosResponseIntercept(value) {
    // Additional check for valid response data...
    // We explicitly check if 'success' is part of the object, otherwise we flag
    // non-API responses without a success field as invalid even though they may be
    // perfectly valid.
    if (value.data && value.data.hasOwnProperty('success') && !value.data.success) {
        return Promise.reject(value.data.error || "API endpoint marked call unsuccessful")
    }

    return value;
}

function axiosErrorIntercept(error) {
    // When 401 unauthorized, redirect the user to the login page.
    if (error.response.status === 401) {
        authenticationService.logout()
    }
}

function currentToken() {
    const {authentication: authState } = store.getState();

    return authState.token;
}

export const apiServices = {
    axios: axiosInstance,
    currentToken
};
