import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class AppHeader extends React.Component {

    render() {
        return (
            <header className="page__header">
                <div className="logo-holder">
                    <Link to="/" className="logo-text d-none d-lg-block">
                        <strong className="text-primary">Server</strong><strong>Grip</strong>
                    </Link>
                    <Link to="/" className="logo-text d-lg-none">
                        <strong className="text-primary">S</strong><strong>G</strong>
                    </Link>
                </div>

                <div className="box">
                    <form className="page-header-search" id="header_search">
                        <input type="text" className="form-control" placeholder="Search..."/>
                        <div className="page-header-search__icon"></div>
                    </form>
                </div>

                <div className="box-fluid"></div>

                <div className="box">
                    <button className="btn btn-light btn-icon" data-action="fixedpanel-toggle">
                        <span className="li-cog"></span>
                    </button>
                </div>
            </header>
        );
    }

}

function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(AppHeader);
export {connected as AppHeader};
