import React from "react";
import {serverServices} from "../_services/servers.services";
import {Link} from "react-router-dom";
import Humanize from "humanize-plus";
import TimeAgo from 'react-timeago';

export default class FileManager extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			path: '/',
			entries: []
		};

		this.fetch(this.state.path);
		this.navigate = this.navigate.bind(this);
	}

	fetch(path) {
		serverServices.ls(this.props.serverId, path).then(entries => this.setState({entries: entries}));
	}

	navigate(entry) {
		let path = this.state.path;

		if (entry == '..') {
			if (path === '/') {
				return;
			}

			path = path.substr(0, path.lastIndexOf('/'));
			if (path === '') {
				path = '/';
			}
		} else if (path === '/') {
			path += entry;
		} else {
			path += "/" + entry;
		}

		this.setState({path});
		this.fetch(path);
	}

	render() {
		const entries = [{directory: true, name: "..", size: 0, modTime: 0, mode: 0}].concat(this.state.entries);
		const rows = entries.map(e => <tr><td>{icon(e)}</td><td><a onClick={ev => this.navigate(e.name)}>{e.name}</a></td><td>{Humanize.fileSize(e.size)}</td><td>{new Date(e.modTime * 1000).toLocaleString()}</td><td>{e.mode.toString(8)}</td></tr>);

		return (
			<div>
                <div className="row">
                    <div className="col-md-12">
                        <Link to={`/servers/${this.props.serverId}`} className="btn btn-primary btn--icon"><span className="fa fa-arrow-left"></span> Back to apps</Link>
                    </div>
                </div>

                <hr />
    
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 id="rw-tb-stripped">File explorer.</h4>

                                <div className="table-responsive">
									<table className="table table-bordered margin-bottom-0">
										<thead>
											<tr><th style={{width: '30px'}}></th><th>Name</th><th>Size</th><th>Modified</th><th>Mode</th></tr>
										</thead>
										<tbody>
											{rows}
										</tbody>
									</table>
								</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		);
	}

}

function icon(entry) {
	if (entry.directory) {
		return <i className="li li-folder"></i>;
	} else {
		return <i className="li li-file-empty"></i>
	}
}