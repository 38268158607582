
import { serverConstants } from "../_constants";

const initialState = [];

export function servers(state = initialState, action) {
    switch (action.type) {
        case serverConstants.GET_ALL_RESPONSE:
            return action.servers;
        case serverConstants.GET_RESPONSE:
            let servers = state;
            let id = action.id;

            // Find existing server in array to map state
            for (let i = 0; i < servers.length; i++) {
                if (servers[i] && servers[i].id === id) {
                    servers[i] = action.server;
                    return servers;
                }
            }
            
            // Not found in state so add as new entry.
            return state.concat(action.server);
        default:
            return state;
    }
}
