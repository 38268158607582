
const prefix = 'LOGIN_';

export const authenticationConstants = {
    ERROR: prefix + 'ERROR',
    CLEAR_ERROR: prefix + 'CLEAR_ERROR',
    LOGGING_IN: prefix + 'LOGGING_IN',
    LOGGED_IN: prefix + 'LOGGED_IN',
    LOGOUT: prefix + 'LOGOUT',

    REGISTERING: prefix + 'REGISTERING',
    REGISTERED: prefix + 'REGISTERED',
};
