import React from 'react';
import { connect } from 'react-redux';

import { authenticationActions } from './_actions';
import {Link} from "react-router-dom";
import './LoginPage.scss';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        };

        // Bind functions to component instance
        this.submitLogin = this.submitLogin.bind(this);
        this.applyForm = this.applyForm.bind(this);
    }

    submitLogin(e) {
        e.preventDefault();

        const { email, password } = this.state;
        const { dispatch } = this.props;

        if (email && password) {
            dispatch(authenticationActions.clearError());
            dispatch(authenticationActions.login(email, password));
        }

        return false;
    }

    /**
     * Handles the two-way binding when editing a form field.
     * @param {Event} e the event passed from the browser
     */
    applyForm(e) {
        const { name, value } = e.target;
        this.setState({[name]: value});
    }

    render() {
        const { email, password } = this.state;
        const { loggingIn, error } = this.props;

        return (
            <div className="page page--w-header page--w-container">
                <div className="full-page-container">
                    <div className="d-flex flex-row justify-content-center px-4 mt-9vh">
                        <div className="card logincard">
                            <div className="card-header py-3  d-flex justify-content-between align-items-center login-header">
                                <h5>Login to ServerGrip</h5>

                                <div className="media-box media-box-sm bg-primary text-white">
                                    <i className="mdi mdi-lock-outline mdi-24px mr-1"></i>
                                </div>
                            </div>

                            <div className="card-body">
                                { error && this.renderError() }

                                <form onSubmit={this.submitLogin}>
                                    <div className="form-group mb-4 mt-2">
                                        <label for="emailInput">Email address</label>
                                        <input type="email" name="email" className="form-control" id="emailInput" placeholder="" value={email} onChange={this.applyForm}/>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label for="passwordInput">Password</label>
                                        <input type="password" name="password" className="form-control" id="passwordInput" placeholder="" value={password} onChange={this.applyForm} />
                                    </div>

                                    { /*
                                    <div className="form-check mb-2 py-2">
                                        <label className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" />
                                                <span className="custom-control-indicator"></span>
                                                <span className="custom-control-description">Keep Me Logged In</span>
                                        </label>
                                    </div>
                                    */ }

                                    <div className="py-1">
                                        <button type="submit" disabled={loggingIn} className="btn btn-primary btn-block">Submit</button>
                                    </div>

                                    <div className="py-2 px-0">
                                        <p className="mb-0">
                                            <button className=" btn btn-link pl-1">Forgot Password?</button>
                                        </p>
                                    </div>

                                    { false &&
                                        <div className="py-0 mt-2 d-flex justify-content-around">
                                        <button className="btn btn-flat-info"><i className="fa fa-twitter"></i></button>
                                        <button className="btn btn-flat-danger"><i className="fa fa-google"></i></button>
                                        <button className="btn btn-flat-primary"><i className="fa fa-facebook"></i></button>
                                        <button className="btn btn-flat-warning"><i className="fa fa-stack-overflow"></i></button>
                                        </div>
                                    }
                                </form>
                            </div>

                            <div className="card-footer">
                                <Link className="btn btn-outline-primary btn-block" role="button" to="/register">
                                    Don't have an account? Register now.
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderError() {
        if (!this.props.error) {
            return;
        }

        return (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Oops!</strong> { LoginPage.remapError(this.props.error) }
            </div>
        );
    };

    static remapError(type) {
        switch (type) {
            case 'invalid_details':
                return 'Invalid email or password.';
            default:
                return type;
        }
    }

}

function mapStateToProps(state) {
    return state.authentication;
}

const connected = connect(mapStateToProps)(LoginPage);
export { connected as LoginPage };

