import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { servers } from './servers.reducer';

const rootReducer = combineReducers({
    authentication,
    servers
});

export default rootReducer;
