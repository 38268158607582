import React from 'react';

import Modal from "react-bootstrap/es/Modal";
import Button from "react-bootstrap/es/Button";

class InstallationModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 0
        };

        this.changeTab = this.changeTab.bind(this);
    }

    changeTab(tab) {
        if (this.state.tab !== tab) {
            this.setState({tab: tab});
        }
    }

    render() {
        const { license, show, onHide } = this.props;
        const { tab } = this.state;

        return (
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Adding a new server to ServerGrip
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-6 col-lg-3" style={{borderRight: '1px solid rgba(0, 0, 0, 0.1)'}}>
                            <h4>Operating System</h4>

                            <div className="nav flex-column nav-pills">
                                <NavTab active={tab === 0} onClick={() => this.changeTab(0)}>Ubuntu &amp; Debian</NavTab>
                                <NavTab active={tab === 1} onClick={() => this.changeTab(1)}>CentOS 7</NavTab>
                                <NavTab active={tab === 2} onClick={() => this.changeTab(2)}>Other</NavTab>
                            </div>
                        </div>

                        <div className="col-6 col-lg-9">
                            <div className="tab-content" id="v-pills-tabContent">
                                <SectionTab show={tab === 0}>
                                    <h3>Installing on Ubuntu</h3>

                                    <p>Installation on Ubuntu requires <code>curl</code> to be installed
                                    on the system.</p>

                                    <p>Connect to the server you want to add to ServerGrip. When you are connected, enter the code below in the server terminal to install ServerGrip.</p>

                                    <pre>sudo bash -c "$(curl -L https://api.servergrip.com/api/v1/agent/install.sh?key={license})"</pre>

                                    <p>After the installation has completed, your server list will contain your
                                        new server. This should take no more than 30 seconds.</p>
                                </SectionTab>

                                <SectionTab show={tab === 1}>
                                    <h3>Installing on CentOS 7</h3>

                                    <p>Installation on CentOS 7 requires <code>curl</code> to be installed
                                        on the system.</p>

                                    <p>Connect to the server you want to add to ServerGrip. When you are connected, enter the code below in the server terminal to install ServerGrip.</p>

                                    <pre>sudo bash -c "$(curl -L https://api.servergrip.com/api/v1/agent/install-rpm.sh?key={license})"</pre>

                                    <p>After the installation has completed, your server list will contain your
                                        new server. This should take no more than 30 seconds.</p>
                                </SectionTab>

                                <SectionTab show={tab === 2}>
                                    <h3>Operating system not listed?</h3>

                                    <p>ServerGrip is currently only available on Debian- and RedHat-based Linux operating systems. We're working hard to add more platforms.</p>

                                    <p>Our apologies for the inconvenience.</p>
                                </SectionTab>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={onHide}>Close window</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

function SectionTab(props) {
    if (!props.show) {
        return null;
    }

    return (
        <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            {props.children}
        </div>
    )
}

function NavTab(props) {
    return (
        <a className={'nav-link ' + (props.active ? 'active' : '')} href="#" onClick={props.onClick}>{props.children}</a>
    );
}

export { InstallationModal };
