import React from "react";
import {Link} from "react-router-dom";
import { serverServices } from "../_services/servers.services";

// eslint-disable-next-line import/no-webpack-loader-syntax
import lib from '!!raw-loader!./Docker.ts';

export default class Docker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            containers: []
        };

        this.invoke('Docker.printContainers()').then(containers => this.setState({containers: JSON.parse(containers)}));
    }

    render() {
        const containers = this.state.containers.map(c => <Docker.ContainerRow container={c} />);

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Link to={`/servers/${this.props.serverId}`} className="btn btn-primary btn--icon"><span className="fa fa-arrow-left"></span> Back to apps</Link>
                    </div>
                </div>

                <hr />
    
                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 id="rw-tb-stripped">Docker containers</h4>

                                <div className="table-responsive">
                                    <table className="table table-bordered margin-bottom-0">
                                        <thead>
                                            <td>Name(s)</td>
                                            <td>ID</td>
                                            <td>Image</td>
                                            <td>Networks</td>
                                            <td>Status</td>
                                        </thead>
                                        <tbody>
                                            {containers}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    static ContainerRow(props) {
        const {container} = props;
        console.log(container);

        return (
            <tr key={container.ID}>
                <td><b>{container.Names}</b></td>
                <td>{container.ID}</td>
                <td>{container.Image}</td>
                <td>{container.Networks}</td>
                <td>{container.Status}</td>
            </tr>
        );
    }

    invoke(descriptor) {
        return serverServices.execute(this.props.serverId, lib + ';;\n' + descriptor);
    }

}
