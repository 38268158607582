import React from 'react';
import {connect} from "react-redux";
import {serverActions} from "./_actions/servers.actions";
import {ServerListEntry} from "./ServerListEntry";
import {InstallationModal} from "./InstallationModal";

class ServerList extends React.Component {

    constructor(props) {
        super(props);

        // Set up the periodic interval to refresh server stats
        this.refreshServers = this.refreshServers.bind(this);
        const refreshInterval = window.setInterval(this.refreshServers, 5000);

        this.state = {
            refreshIntervalId: refreshInterval,
            installModalOpen: false,
            hideInactive: false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.openModal = this.openModal.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);

        // Trigger a server reload
        this.refreshServers();
    }

    componentWillUnmount() {
        window.clearInterval(this.state.refreshIntervalId);
    }

    refreshServers() {
        this.props.dispatch(serverActions.getAll());
    }

    openModal() {
        this.setState({installModalOpen: true});
    }

    closeModal() {
        // Avoid continuously re-rendering the component
        if (this.state.installModalOpen) {
            this.setState({installModalOpen: false})
        }
    }

    toggleCheckbox(event) {
        this.setState({
            hideInactive: event.target.checked
        });
    }

    render() {
        const servers = this.props.servers.map(s => <ServerListEntry server={s} key={s.summary.id} hideInactive={this.state.hideInactive} />);
        const { installModalOpen } = this.state;
        const { license } = this.props;

        return (
            <div className="card margin-bottom-0">
                <div className="widget invert widget--items-middle">
                    <div className="widget__icon_layer widget__icon_layer--right">
                        <span className="li-database-upload" />
                    </div>
                    <div className="widget__container">
                        <div className="widget__line">
                            <div className="widget__icon">
                                <span className="li-server" />
                            </div>

                            <div className="widget__title">Servers</div>

                            <div className="widget__subtitle">
                                Quick overview of all registered servers. Updated just now.
                            </div>
                        </div>

                        <div className="widget__box">
                            <button onClick={this.openModal} className="btn btn-primary btn--icon">
                                <span className="fa fa-server" /> Add a new server
                            </button>
                        </div>
                    </div>
                </div>

                <div className="card-body">
                    <input type="checkbox" id="filter-checkbox" value={this.state.hideInactive} onChange={this.toggleCheckbox}/>
                    <label htmlFor="filter-checkbox">Hide inactive servers</label>

                    <div className="table-responsive">
                        <table className="table margin-bottom-0">
                            <thead>
                            <tr>
                                <th scope="col" width="10"></th>
                                <th scope="col" width="80">Hostname</th>
                                <th scope="col" width="140">Operating System</th>
                                <th scope="col" width="130">CPU Usage</th>
                                <th scope="col" width="130">Memory Usage</th>
                                <th scope="col" width="130">Disk Usage</th>
                                <th scope="col" width="100"></th>
                            </tr>
                            </thead>

                            <tbody>
                            {servers}
                            </tbody>
                        </table>
                    </div>
                </div>

                <InstallationModal license={license} show={installModalOpen} onHide={this.closeModal} />
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {servers: state.servers, license: state.authentication.license};
}

const connected = connect(mapStateToProps)(ServerList);
export {connected as ServerList};
