
import { authenticationConstants } from "../_constants";

// Grab the session token that's stored in the browser. If it's not valid, any call made will eventually
// make the app redirect to the login page and clear the session.
let token = localStorage.getItem('session-token');

// Parse that token into an auth key
let license = token && extractLicenseFromJwt(token);

const initialState = {
    token,
    license,
    loggingIn: false
};

function extractLicenseFromJwt(jwtToken) {
    let tokenData = JSON.parse(atob(jwtToken.split('.')[1]));

    if (tokenData && tokenData.license) {
        return tokenData.license;
    }

    return null;
}

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationConstants.ERROR:
            return {
                ...state,
                error: action.error,
                loggingIn: false
            };
        case authenticationConstants.CLEAR_ERROR:
            return {
                ...state,
                error: undefined,
                loggingIn: false
            };
        case authenticationConstants.LOGGING_IN:
            return {
                ...state,
                loggingIn: true
            };
        case authenticationConstants.LOGGED_IN:
            // Store the token in the local storage so that we can authenticate again next run.
            localStorage.setItem('session-token', action.token);

            return {
                ...state,
                token: action.token,
                loggingIn: false,
                license: action.token && extractLicenseFromJwt(action.token),
                error: undefined
            };
        case authenticationConstants.LOGOUT:
            return {
                ...state,
                token: undefined,
                loggingIn: false
            };
        case authenticationConstants.REGISTERING:
            return {
                ...state,
                error: undefined,
                loggingIn: true
            };
        case authenticationConstants.REGISTERED:
            // Store the token in the local storage so that we can authenticate again next run.
            localStorage.setItem('session-token', action.token);

            return {
                ...state,
                token: action.token,
                loggingIn: false,
                license: action.token && extractLicenseFromJwt(action.token),
                error: undefined
            };
        default:
            return state;
    }
}
