import React, {lazy, Suspense} from 'react';
import Button from "react-bootstrap/es/Button";
import { serverServices } from "./_services";

import defaultCode from './CodeConsoleDefault.txt';
import extraTsLib from './CodeConsoleExtraLib.txt';

const MonacoEditor = lazy(() => import('react-monaco-editor'));

class CodeConsole extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            monaco: React.createRef(),
            outputArea: React.createRef(),

            codeOutput: 'Output will appear here.'
        };

        this.didMount = this.didMount.bind(this);
        this.runCode = this.runCode.bind(this);

        window.addEventListener("resize", this.resizeEditor.bind(this));
    }

    render() {
        const editorConfig = {
            selectOnLineNumbers: true
        };

        return (
            <div className="row">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body"><h4 id="rw-tb-hoverable">Code editor</h4>
                            <p className="subtitle margin-bottom-20">You can enter TypeScript code below to run it on the server. </p>

                            <Suspense fallback={<div>Loading the code editor... Please wait.</div>}>
                                <MonacoEditor language="typescript" ref={this.state.monaco} options={editorConfig} width="100%" height="480" editorDidMount={this.didMount}/>

                                <hr />
                                <Button variant="outline-primary" onClick={this.runCode}>Execute on {this.props.server.alias || this.props.server.summary.hostname }</Button>
                            </Suspense>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body"><h4 id="rw-tb-hoverable">Runtime output</h4>
                            <p className="subtitle margin-bottom-20">Once the execution has finished, the resulting code will appear here.</p>

                            <textarea className="form-control" ref={this.state.outputArea} style={{width: '100%', height: '480px'}} readOnly={true} value={this.state.codeOutput}/>

                            <hr />

                            <Button variant="btn-outline-primary">Save output</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    didMount(editor, monaco) {
        monaco.languages.typescript.typescriptDefaults.addExtraLib(extraTsLib);

        const lastCode = localStorage.getItem('last-code');

        if (lastCode && lastCode.length) {
            editor.setValue(lastCode);
        } else {
            editor.setValue(defaultCode)
        }
    }

    runCode() {
        const code = this.state.monaco.current.editor.getValue();
        localStorage.setItem('last-code', code);

        const transpiled = window.ts.transpile(code);

        serverServices.execute(this.props.server.id, transpiled).then(
            resp => {
                this.setState({codeOutput: resp});
            },
            err => {
                this.setState({codeOutput: 'Error executing code.'})
            }
        );
    }

    resizeEditor() {
        const { monaco } = this.state;

        if (monaco && monaco.current) {
            monaco.current.editor.layout();
        }
    }

}

export { CodeConsole };
