import React from "react";
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {Redirect, Router, Switch} from "react-router";
import {PrivateRoute} from "./_components";
import {ServerList} from "./ServerList";
import {Server} from "./Server";
import {AppHeader} from "./AppHeader";
import {NavBar} from "./NavBar";
import {history} from './_helpers';

import "./AuthLayout.scss";

class AuthLayout extends React.Component {

    render() {
        return (
            <div>
                <div className="page page--w-header page--w-container">
                    <AppHeader />
                    <NavBar />

                    <div className="page__content page-aside--hidden" id="page-content">
                        <div className="content" id="content">
                            <div className="page-heading">
                                <div className="page-heading__container">
                                    <div className="icon"><span className="li-home3"></span></div>
                                    <h1 className="title">Dashboard</h1>
                                    <p className="caption">Manage your servers in a convenient overview</p>
                                </div>
                                <div className="page-heading__container float-right d-none d-sm-block">
                                </div>
                                <nav aria-label="breadcrumb" role="navigation">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                        <li className="breadcrumb-item active">Overview</li>
                                    </ol>
                                </nav>
                            </div>

                            <div className="container-fluid">
                                <Router history={history}>
                                    <Switch>
                                        {/* redirect the /servers path to / because the landing page is the server list page */}
                                        <Route exact path="/servers" render={() => (
                                            <Redirect to="/"/>
                                        )}/>

                                        <PrivateRoute exact path="/" component={ServerList}/>
                                        <PrivateRoute path={`/servers/:serverId`} component={Server}/>
                                    </Switch>
                                </Router>
                            </div>

                            <div className="text-muted app-version-footer">
                                Build { process.env.VERSION } released at { process.env.BUILT_AT }.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return state;
}

const connected = connect(mapStateToProps)(AuthLayout);
export { connected as AuthLayout };
