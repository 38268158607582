import React from 'react';
import {Router, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Switch} from "react-router";

import {history} from './_helpers';
import {LoginPage} from './LoginPage';
import {RegisterPage} from "./RegisterPage";
import {AuthLayout} from "./AuthLayout";
import {CodeConsole} from "./CodeConsole";

import './styling/styles.scss';

const TerminalView = React.lazy(() => import("./TerminalView"));

const SuspenseTerminalView = (props) => {
    return <React.Suspense fallback={<div>Loading terminal...</div>}>
        <TerminalView {...props} />
    </React.Suspense>
};

class App extends React.Component {

    constructor(props) {
        super(props);

        const {dispatch} = this.props;

        history.listen((location, action) => {
            dispatch(() => {
            });
        });
    }

    render() {
        return (
            <Router history={history}>
                <Switch>
                    {/* Authentication pages: */}
                    <Route path="/login" component={LoginPage}/>
                    <Route path="/register" component={RegisterPage}/>

                    <Route path="/terminal/:serverId" component={SuspenseTerminalView} />

                    <Route path="/" component={AuthLayout}/>
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return state; // For now.. :)
}

const connected = connect(mapStateToProps)(App);
export default connected;
