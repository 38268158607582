import React from 'react';
import {connect} from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import {authenticationActions} from './_actions';
import {Link} from "react-router-dom";
import './RegisterPage.scss';

class RegisterPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            password2: '',
            captchaKey: undefined,
            canSubmit: false,
        };

        // Bind functions to component instance
        this.submitForm = this.submitForm.bind(this);
        this.applyForm = this.applyForm.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
        this.isFormOk = this.isFormOk.bind(this);

        // Reset login state

    }

    submitForm(e) {
        e.preventDefault();

        const {email, password, captchaKey} = this.state;
        const {dispatch} = this.props;

        if (email && password && captchaKey) {
            dispatch(authenticationActions.clearError());
            dispatch(authenticationActions.register(email, password, captchaKey));
        }

        return false;
    }

    /**
     * Handles the two-way binding when editing a form field.
     * @param {Event} e the event passed from the browser
     */
    applyForm(e) {
        const {name, value} = e.target;

        this.setState({[name]: value}, () => {
            const canSubmit = this.isFormOk();
            this.setState({canSubmit});
        });
    }

    onCaptchaChange(value) {
        this.setState({captchaKey: value});
    }

    isFormOk() {
        const {email, password, password2} = this.state;

        if (!email || !password || !password2 || password !== password2) {
            return false;
        }

        return true;
    }

    render() {
        const {loggingIn, error} = this.props;
        const {canSubmit} = this.state;

        return (
            <div>
                <div className="d-flex flex-row justify-content-center px-4 mt-9vh">
                    <div className="card registercard">
                        <div className="card-header py-3 d-flex justify-content-between align-items-center register-header">
                            <h5>Create a free account</h5>

                            <div className="media-box media-box-sm bg-primary text-white">
                                <i className="mdi mdi-lock-outline mdi-24px mr-1"></i>
                            </div>
                        </div>

                        <div className="card-body">
                            { error && this.renderError() }

                            <form className="" method="POST" action="#" onSubmit={this.submitForm}>
                                <div className="form-group mb-4 mt-2">
                                    <label htmlFor="email">Email address</label>
                                    <input type="email" name="email" onChange={this.applyForm} className="form-control" id="email" placeholder=""/>

                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" name="password" onChange={this.applyForm} className="form-control" id="password" placeholder=""/>
                                </div>

                                <div className="form-group mb-4">
                                    <label htmlFor="password2">Repeat password</label>
                                    <input type="password" name="password2" onChange={this.applyForm} className="form-control" id="password2" placeholder=""/>
                                </div>

                                <div className="form-group mb-4">
                                    <label>Human verification</label>

                                    <ReCAPTCHA sitekey="6Lcvl0YUAAAAALCMsRyk3jcWgji3cmvgo9lUNiQ6" onChange={this.onCaptchaChange}/>
                                </div>

                                <div className="form-check mb-2 py-2">
                                    <input type="checkbox" className="" style={{marginRight: '10px'}}/> I agree to the <a href="#" className="text-info">Terms and Conditions</a>.
                                </div>

                                <div className="py-1">
                                    <button type="submit" disabled={loggingIn || !canSubmit} className="btn btn-primary btn-block ">Register</button>
                                </div>
                            </form>
                        </div>

                        <div className="card-footer">
                            <Link className="btn btn-outline-primary btn-block" role="button" to="/login">
                                Already have an account? Click to log in.
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderError() {
        if (!this.props.error) {
            return;
        }

        return (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Oops!</strong> {RegisterPage.remapError(this.props.error)}
            </div>
        );
    };

    static remapError(type) {
        switch (type) {
            case 'invalid_details':
                return 'Invalid email or password.';
            default:
                return type;
        }
    }

}

function mapStateToProps(state) {
    return state.authentication;
}

const connected = connect(mapStateToProps)(RegisterPage);
export {connected as RegisterPage};

