import React from 'react';
import {connect} from "react-redux";
import {Link, Route} from "react-router-dom";
import {CodeConsole} from "./CodeConsole";
import TimeAgo from 'react-timeago';
import {ServerListEntry} from "./ServerListEntry";
import {history} from "./_helpers";
import {Redirect, Router, Switch} from "react-router";
import {PrivateRoute} from "./_components";
import {ServerList} from "./ServerList";
import {serverActions} from "./_actions/servers.actions";
import UserManagement from "./Apps/UserManagement.jsx";
import FileManager from "./Apps/FileManager.jsx";
import Docker from "./Apps/Docker.jsx";

class Server extends React.Component {

    constructor(props) {
        super(props);

        this.props.dispatch(serverActions.get(this.props.serverId));
    }

    render() {
        const { server } = this.props;

        if (!server) {
            return (
                <div>
                    <Link to="/">Back to listing</Link>

                    <hr/>

                    <b>Error loading server.</b>
                </div>
            );
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Link to="/" className="btn btn-primary btn--icon"><span className="fa fa-arrow-left"></span> Back to listing</Link>
                    </div>
                </div>

                <hr/>

                <div className="row">
                    <div className="col-lg-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="user user--rounded user--bordered user--xlg margin-bottom-0">
                                    <div className="icon-box-server" style={{width: '60px', height: '60px', float: 'left', verticalAlign: 'middle'}}>
                                        <span className="li li-server" style={{fontSize: '60px'}} />
                                    </div>

                                    <div className="user__name" style={{paddingLeft: '75px'}}>
                                        <strong>{server.summary.alias || server.summary.hostname}</strong>

                                        <br/>

                                        <a href="#">{server.summary.ip}</a>

                                        <br/>

                                        {false && // TODO: Make this display when it was registered
                                        <span className="text-muted">
                                            <TimeAgo date={server.summary.last_seen}></TimeAgo>
                                        </span>
                                        }
                                    </div>
                                </div>

                                { false && <div>
                                <button className="btn btn-light btn-block">Profile settings</button>
                                <button className="btn btn-light btn-block">Money transfers</button>
                                <button className="btn btn-light btn-block">Insurance</button>
                                </div>}
                            </div>

                            <div className="divider margin-top-10 margin-bottom-10"></div>

                            <div className="list-group list-group-flush">
                                <div className="margin-bottom-10" style={{textAlign: 'center'}}><b>System management</b>
                                </div>

                                <a href="#" onClick={() => ServerListEntry.openTerminalPopup(server)} className="list-group-item list-group-item-action">
                                    <span className="fa fa-terminal" /> Open terminal
                                </a>

                                <Link to={`/servers/${server.id}/code`} className="list-group-item list-group-item-action">
                                    <span className="fa fa-code" /> Code editor
                                </Link>

                                <a href="#" className="list-group-item list-group-item-action">
                                    <span className="fa fa-user" /> User management
                                </a>

                                <a href="#" className="list-group-item list-group-item-action">
                                    <span className="fa fa-cc-visa" /> Processes
                                </a>
                            </div>

                            <div className="divider margin-top-20 margin-bottom-10"></div>

                            <div className="list-group list-group-flush">
                                <div className="margin-bottom-10" style={{textAlign: 'center'}}><b>Services</b></div>

                                <a href="#" className="list-group-item list-group-item-action">
                                    <span className="fa fa-cog"></span> SSHD service
                                </a>
                            </div>

                            <div className="divider margin-bottom-10"></div>

                            <div className="card-body">
                                <div className="row margin-bottom-10">
                                    <div className="col-6">
                                        <p>
                                            Running as<br/>
                                            <strong>{server.summary.username}</strong>
                                        </p>
                                    </div>

                                    <div className="col-6">
                                        <p>
                                            Home directory<br/>
                                            <strong>{server.summary.homedir}</strong>
                                        </p>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>
                                            Agent version<br/>
                                            <strong>{server.summary.agent_version}</strong>
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p>
                                            Operating system<br/>
                                            <strong>{server.summary.os}</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {false &&
                            <div className="divider margin-bottom-10 margin-top-10"></div>
                            }

                            {false &&
                            <div className="card-body">
                                <div className="row margin-bottom-10">
                                    <div className="col-6">
                                        <p>
                                            Cards<br/>
                                            <strong>3 Active</strong>
                                        </p>
                                    </div>

                                    <div className="col-6">
                                        <p>
                                            Verified<br/>
                                            <strong>Confirmed</strong>
                                        </p>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <p>
                                            Status<br/>
                                            <strong>Active</strong>
                                        </p>
                                    </div>
                                    <div className="col-6">
                                        <p>
                                            Docs<br/>
                                            <strong>Uploaded 3 of 5</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            }

                            <div className="divider margin-top-10 margin-bottom-10"></div>

                            <div className="page-heading">
                                <div className="page-heading__container">
                                    <h1 className="title">Server activity</h1>
                                    <p className="caption">Real-time status feed</p>
                                </div>

                                <div className="page-heading__container float-right">
                                    <button className="btn btn-light btn-icon"><span className="fa fa-refresh"/>
                                    </button>
                                </div>
                            </div>

                            <div className="list-group list-group-flush"><a href="#"
                                                                            className="list-group-item list-group-item-action flex-column align-items-start border-top-0">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">USA market goes...</h5>
                                    <small>1 day ago</small>
                                </div>
                                <p className="mb-1">Fusce euismod, nisi a ornare faucibus, erat metus.</p>
                            </a><a href="#"
                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">CBA network something...</h5>
                                    <small className="text-muted">2 days ago</small>
                                </div>
                                <p className="mb-1">Nullam vestibulum nec sapien a sodales...</p>
                            </a><a href="#"
                                   className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">Google purchased new...</h5>
                                    <small className="text-muted">3 days ago</small>
                                </div>
                                <p className="mb-1">Sed ultrices, dui at aliquam mattis...</p>
                            </a></div>
                            <div className="card-body padding-top-5 padding-bottom-5">
                                <button className="btn btn-link btn-block">View full activity log</button>
                            </div>
                        </div>
                    </div>

                    {this.renderContent()}
                </div>
            </div>
        );
    }

    renderContent() {
        const { server, serverId } = this.props;

        return (
            <div className="col-md-10">
                <Router history={history}>
                    <Switch>
                        <PrivateRoute exact path={`/servers/:serverId`} component={() => <ApplicationButtons serverId={serverId} server={server} />}/>
                        <PrivateRoute path={`/servers/:serverId/code`} component={() => <CodeConsole server={server} />}/>
                        <PrivateRoute path={`/servers/:serverId/users`} component={() => <UserManagement serverId={serverId} server={server} />}/>
                        <PrivateRoute path={`/servers/:serverId/files`} component={() => <FileManager serverId={serverId} server={server} />}/>
                        <PrivateRoute path={`/servers/:serverId/docker`} component={() => <Docker serverId={serverId} server={server} />}/>
                    </Switch>
                </Router>
            </div>
        );
    }

}

function ApplicationButtons(props) {
    const { serverId } = props;

    return (
        <div>
            <h1>System management</h1>
            <div class="row">
                <div class="col-6 col-lg-2 margin-bottom-20">
                    <Link to={`/servers/${serverId}/files`} className="widget widget--box">
                        <div class="widget__container">
                            <div class="widget__line">
                                <div class="widget__icon">
                                    <span class="li-files"></span>
                                </div>
                                <div class="widget__title">Files</div>
                                <div class="widget__subtitle">Browse and edit files</div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-6 col-lg-2 margin-bottom-20">
                    <Link to={`/servers/${serverId}/users`} className="widget widget--box">
                        <div class="widget__container">
                            <div class="widget__line">
                                <div class="widget__icon">
                                    <span class="li-users2"></span>
                                </div>
                                <div class="widget__title">Users</div>
                                <div class="widget__subtitle">Manage system accounts</div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="col-6 col-lg-2 margin-bottom-20">
                    <a href="#" class="widget widget--box">
                        <div class="widget__container">
                            <div class="widget__line">
                                <div class="widget__icon">
                                    <span class="li-lan"></span>
                                </div>
                                <div class="widget__title">Network</div>
                                <div class="widget__subtitle">View network configuration</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 col-lg-2 margin-bottom-20">
                    <a href="#" class="widget widget--box">
                        <div class="widget__container">
                            <div class="widget__line">
                                <div class="widget__icon">
                                    <span class="li-hdd"></span>
                                </div>
                                <div class="widget__title">Disk</div>
                                <div class="widget__subtitle">Manage disks and swap</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 col-lg-2 margin-bottom-20">
                    <a href="#" class="widget widget--box">
                        <div class="widget__container">
                            <div class="widget__line">
                                <div class="widget__icon">
                                    <span class="li-cog"></span>
                                </div>
                                <div class="widget__title">Processes</div>
                                <div class="widget__subtitle">Manage running processes</div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-6 col-lg-2 margin-bottom-20">
                    <Link to={`/servers/${serverId}/docker`} className="widget widget--box">
                        <div class="widget__container">
                            <div class="widget__line">
                                <div class="widget__icon">
                                    <span class="li-ship2"></span>
                                </div>
                                <div class="widget__title">Docker</div>
                                <div class="widget__subtitle">Manage Docker containers</div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    const { match: { params: { serverId } } } = ownProps;

    // Grab server ID from the state
    let server;

    if (state.servers) {
        for (let i = 0; i < state.servers.length; i++) {
            if (state.servers[i].summary.id === serverId) {
                server = state.servers[i];
                break;
            }
        }
    }

    return { serverId, server } ;
}

const connected = connect(mapStateToProps)(Server);
export { connected as Server };
