import axios from 'axios';
import { store } from "../_helpers";
import { history } from "../_helpers";
import { authenticationActions } from "../_actions";

export const authenticationService = {
    login,
    register,
    logout
};

function login(email, password) {
    return axios.post(process.env.CONFIG_API_BASE + '/auth/login', {email, password})
        .then(resp => {
            if (resp.data.success) {
                return resp.data.data;
            } else {
                return Promise.reject({message: resp.data.error.type});
            }
        });
}

function register(email, password, captcha) {
    return axios.post(process.env.CONFIG_API_BASE + '/auth/register', {email, password, captcha})
        .then(resp => {
            if (resp.data.success) {
                return resp.data.data;
            } else {
                return Promise.reject({message: resp.data.error.type});
            }
        })
}

function logout() {
    // Nuke token and redirect.
    store.dispatch(authenticationActions.logout());
    history.push('/');
}
