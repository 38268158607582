import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class NavBar extends React.Component {

    render() {
        return (
            <div className="page__container">
                <nav className="horizontal-navigation">
                    <ul>
                        <li className="title">Main</li>
                        <li>
                            <Link to="/"><span className="icon li-home"></span><span className="text">Dashboard</span></Link>
                        </li>
                        <li><a href="#"><span className="icon li-cog"></span><span className="text">Activity</span></a></li>
                        <li><a href="#"><span className="icon li-book2"></span><span className="text">Audit log</span></a>
                        </li>
                    </ul>
                </nav>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(NavBar);
export {connected as NavBar};
