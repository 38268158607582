import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {osHelpers} from "./_helpers";
import Humanize from 'humanize-plus';

import './ServerListEntry.scss';
import Button from "react-bootstrap/es/Button";

class ServerListEntry extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            terminal: false
        };

        this.nameColumn = this.nameColumn.bind(this);
        this.osColumn = this.osColumn.bind(this);
        this.cpuColumn = this.cpuColumn.bind(this);
        this.memoryColumn = this.memoryColumn.bind(this);
        this.diskColumn = this.diskColumn.bind(this);
        this.actionsColumn = this.actionsColumn.bind(this);

        this.serverActive = this.serverActive.bind(this);
    }

    render() {
        const active = this.serverActive();

        // Don't render if the list tells us only to render actives and we're inactive.
        if (this.props.hideInactive && !active) {
            return null;
        }

        return (
            <tr className={!this.serverActive() && 'server-disabled'}>
                <td><span className="fa fa-star-o" /></td>
                {this.nameColumn()}
                {this.osColumn()}
                {this.cpuColumn()}
                {this.memoryColumn()}
                {this.diskColumn()}
                {this.actionsColumn()}
            </tr>
        );
    }

    serverActive() {
        return Date.now() - new Date(this.props.server.summary.last_seen) < 10000;
    }

    nameColumn() {
        const { server } = this.props;

        return (
            <td>
                <span>
                    <b>
                        <Link className="server-title" to={`/servers/${server.id}`}>
                            { server.alias || server.summary.hostname }
                        </Link>
                    </b>
                </span>

                <br/>

                <span className="text-muted"><span className="li li-home mr-1"></span> {server.summary.hostname} <br/></span>
                <span className="text-muted"><span className="li li-earth mr-1"></span> {server.summary.ip} </span>
            </td>
        );
    }

    osColumn() {
        const { server } = this.props;
        const { server: { summary: { os, os_version, kernel_version, platform, platform_version } } } = this.props;

        const operatingSystem = osHelpers.humanize(os, os_version, kernel_version, platform, platform_version);

        return (
            <td>
                {operatingSystem[0]}

                { operatingSystem.length === 2 &&
                <span className="text-muted"><br/>
                    {operatingSystem[1]}

                    <br/>

		            ServerGrip {server.summary.agent_version}

                    {server.summary.agent_version === '0.8.9' &&
                    <span className="badge badge-success ml-2">Latest</span>
                    }
                    </span>
                }
            </td>
        );
    }

    cpuColumn() {
        const { server } = this.props;

        const cpuPercentage = server.cpu.percent.toFixed(0) + '%';
        const cpuColor = ServerListEntry.colorTag(server.cpu.percent, 80, 90);

        return (
            <td>
                {server.cpu.cpus[0].speed.toFixed(0)} MHz, {server.cpu.total} cores<br/>

                <span className="text-muted">
                    { Humanize.fileSize(server.cpu.cpus[0].cachesize) } cache, {cpuPercentage} load
                </span>

                <div className="progress progress-thin" style={{height: "4px"}}>
                    <div className={'progress-bar ' + cpuColor} role="progressbar" style={{ width: cpuPercentage }} />
                </div>
            </td>
        );
    }

    memoryColumn() {
        const { server } = this.props;
        const { memory } = server;

        const memoryUsed = (memory.used / memory.total * 100);
        const memoryPercent = memoryUsed.toFixed(0) + '%';
        const color = ServerListEntry.colorTag(memoryUsed, 70, 90);

        return (
            <td>
                { Humanize.fileSize(server.memory.used) } / { Humanize.fileSize(server.memory.total) }
                <span className="text-muted"> ({ memoryPercent })</span>

                <br/>

                <span className="text-muted">{ Humanize.fileSize(server.memory.total - server.memory.used) } available</span>

                <div className="progress progress-thin" style={{height: "4px"}}>
                    <div className={'progress-bar ' + color} role="progressbar" style={{ width: memoryPercent }} />
                </div>
            </td>
        );
    }

    diskColumn() {
        const { server, server: { disk } } = this.props;

        const diskUsed = (disk.used / disk.total * 100);
        const diskPercent = diskUsed.toFixed(0) + '%';
        const color = ServerListEntry.colorTag(diskUsed, 75, 90);

        return (
            <td>
                { Humanize.fileSize(disk.used) } / { Humanize.fileSize(disk.total) }

                <span className="text-muted">({ diskPercent })</span>

                <br/>

                <span className="text-muted">{ Humanize.fileSize(server.disk.total - server.disk.used) } available</span>

                <div className="progress progress-thin" style={{height: "4px"}}>
                    <div className={'progress-bar ' + color} role="progressbar" style={{ width: diskPercent }} />
                </div>
            </td>
        );
    }

    actionsColumn() {
        const { server } = this.props;

        return (
            <td>
                <Link to={`/servers/${server.id}`} className="btn btn-outline-primary btn-icon">
                    <span className="li-chart-bars" />
                </Link>

                <Button className="btn-icon ml-2" variant="outline-primary"
                        onClick={() => ServerListEntry.openTerminalPopup(server)}>
                    <span className="li-cli" />
                </Button>
            </td>
        );
    }

    static openTerminalPopup(server) {
        let h = 410;
        let w = 980;
        let y = window.outerHeight / 2 + window.screenY - (h / 2);
        let x = window.outerWidth / 2 + window.screenX - (w / 2);
        let url = `${window.location.origin}/terminal/${server.id}?t=${encodeURIComponent(server.summary.hostname)}`;

        return window.open(url, "",
            `toolbar=no,location=no,directories=no,status=no,menubar=no,copyhistory=no,width=${w},height=${h},top=${y},left=${x}`);

    }

    static colorTag(percent, warningFrom, dangerFrom) {
        if (percent >= dangerFrom) {
            return 'bg-danger';
        } else if (percent >= warningFrom) {
            return 'bg-warning';
        }

        return 'bg-success';
    }

}

function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(ServerListEntry);
export { connected as ServerListEntry };