
import { apiServices } from "./api.services";

export const serverServices = {
    listServers,
    execute,
    getServer,
    ls
};

function listServers() {
    return apiServices.axios.get('/servers').then(resp => resp.data.data);
}

function getServer(serverId) {
    return apiServices.axios.get(`/servers/${serverId}`).then(resp => resp.data.data);
}

function execute(serverId, code) {
    return apiServices.axios.post(`/servers/${serverId}/execute`, code).then(resp => resp.data.data);
}

function ls(serverId, path) {
    return apiServices.axios.get(`/servers/${serverId}/ls?path=` + encodeURIComponent(path)).then(resp => resp.data.data);
}
