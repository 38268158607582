export const osHelpers = {
    humanize,
};

function humanize(os, osVersion, kernelVersion, platform, platformVersion) {
    if (os === 'linux') {
        if (platform === 'arch') {
            return ['Arch Linux', "Kernel " + kernelVersion];
        } else if (platform === 'ubuntu') {
            return ['Ubuntu ' + osVersion, "Linux " + kernelVersion];
        } else if (platform === 'debian') {
            return ['Debian ' + osVersion, "Linux " + kernelVersion];
        } else if (kernelVersion.indexOf('amzn2') !== -1) {
            return ['Amazon Linux 2', "Linux " + kernelVersion];
        } else if (kernelVersion.indexOf('amzn') !== -1) {
            return ['Amazon Linux', "Linux " + kernelVersion];
        }
    } else if (os === 'windows') {
        if (platform.indexOf('Server 2016') !== -1) {
            return ['Windows Server 2016', osVersion];
        }
        
        return [platform];
    } else if (os === 'darwin') {
        return [`Mac OS ${osVersion}`, `Darwin kernel ${kernelVersion}`];
    }

    return [`(os=${os}, osver=${osVersion}, kernel=${kernelVersion}, platform=${platform}, platver=${platformVersion})`];
}