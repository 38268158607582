import { authenticationService } from '../_services';
import { history} from "../_helpers";
import { authenticationConstants } from "../_constants";

export const authenticationActions = {
    login,
    logout,
    clearError,
    register
};

function login(email, password) {
    return dispatch => {
        dispatch({ type: authenticationConstants.LOGGING_IN });

        authenticationService.login(email, password).then(
            user => {
                dispatch({type: authenticationConstants.LOGGED_IN, token: user.token});

                history.push('/');
            },
            error => {
                dispatch({type: authenticationConstants.ERROR, error: error.message});
            }
        );
    };
}

function logout() {
    return { type: authenticationConstants.LOGOUT };
}

function clearError() {
    return { type: authenticationConstants.CLEAR_ERROR };
}

function register(email, password, captchaKey) {
    return dispatch => {
        dispatch({ type: authenticationConstants.REGISTERING });

        authenticationService.register(email, password, captchaKey)
            .then(
                user => {
                    dispatch({type: authenticationConstants.REGISTERED, token: user.token });

                    history.push('/');
                },
                error => {
                    dispatch({type: authenticationConstants.ERROR, error: error.message});
                }
            )
    };
}