import React from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import lib from '!!raw-loader!./UserManagement.ts';

import {Link} from 'react-router-dom';
import { serverServices } from "../_services/servers.services";

class UserManagement extends React.Component {

    constructor(props) {
        super(props);

        this.state = { users: null, groups: null };

        this.invoke = this.invoke.bind(this);
        this.invoke('listUsers()').then(users => this.setState({users: JSON.parse(users)}));
        this.invoke('listGroups()').then(groups => this.setState({groups: JSON.parse(groups)}));
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Link to={`/servers/${this.props.serverId}`} className="btn btn-primary btn--icon"><span className="fa fa-arrow-left"></span> Back to apps</Link>
                    </div>
                </div>

                <hr />
    
                <div className="row">
                    <div className="col-md-7">
                        <div className="card">
                            <div className="card-body">
                                <h4 id="rw-tb-stripped">System users</h4>

                                {this.state.users ? this.userList() : <span>Loading users...</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="card">
                            <div className="card-body">
                                <h4 id="rw-tb-stripped">System groups</h4>

                                {this.state.groups ? this.groupList() : <span>Loading group...</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    userList() {
        const users = this.state.users.map(u => (
            <tr key={u.user_id}>
                <td>{u.user_id}</td>
                <td><b>{u.name}</b></td>
                <td>{u.home} <Link to={'/'} className="btn btn-xs btn-outline btn-outline-info btn--icon" style={{marginLeft: '10px'}}><span className="fa fa-folder-o"></span> Browse</Link></td>
                <td>{u.group_id}</td>
                <td>{u.shell}</td>
            </tr>
        ));

        return (
            <div className="table-responsive">
                <table className="table table-bordered margin-bottom-0">
                    <thead>
                        <td>User ID</td>
                        <td>Username</td>
                        <td>Home</td>
                        <td>Group ID</td>
                        <td>Shell</td>
                    </thead>
                    <tbody>
                        {users}
                    </tbody>
                </table>
            </div>
        );
    }

    groupList() {
        const groups = this.state.groups.map(u => (
            <tr key={u.id}>
                <td>{u.id}</td>
                <td><b>{u.name}</b></td>
                <td>{u.users.join(', ')}</td>
            </tr>
        ));

        return (
            <div className="table-responsive">
                <table className="table table-bordered margin-bottom-0">
                    <thead>
                        <td>Group ID</td>
                        <td>Name</td>
                        <td>Users</td>
                    </thead>
                    <tbody>
                        {groups}
                    </tbody>
                </table>
            </div>
        );
    }

    invoke(descriptor) {
        return serverServices.execute(this.props.serverId, lib + ';;\n' + descriptor);
    }

}

export default UserManagement;